import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';
import { GlobalState } from '@core/global-state/app.reducer';
import { RolesAccount } from '@pages/auth/interfaces/auth.constant';
import { PARENT_ROUTES } from '@pages/auth/auth-parent/modules/password/models/constants/password.routes.constants';

@Injectable({
  providedIn: 'root'
})
export class IsTeenRoleGuard implements CanActivate {
  constructor(private store: Store<GlobalState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select('authentication').pipe(
      first(),
      map(auth => auth.user?.role ?? ''),
      map(role => {
        if (role === RolesAccount.TEEN) {
          return true;
        }
        return this.router.createUrlTree([PARENT_ROUTES.PARENTS_HOME]);
      })
    );
  }
}
