import { Injectable, inject } from '@angular/core';
import { RolesAccount } from '@pages/auth/interfaces/auth.constant';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogsManager } from '@core/logs/logs.manager';

@Injectable({
  providedIn: 'root'
})
export class UserRoleManager {
  private roleSubject = new BehaviorSubject<string>(RolesAccount.PARENT);
  private logsManager :LogsManager = inject(LogsManager)
  private role$: Observable<string> = this.roleSubject.asObservable();
  private ERROR_MESSAGE= `Invalid role provided:`
  public setRole(role: string): void {
    if (role !== RolesAccount.PARENT && role !== RolesAccount.TEEN ) {
      const error = new Error(this.ERROR_MESSAGE + `${role}`);
      this.logsManager.error({
        title: "Roles Manager - setRole",
        error: error,
      });
      return;
    }
    try {
      this.roleSubject.next(role);
    } catch (e) {
      this.logsManager.error({
        title: "Roles Manager - catch setRole",
        error: e,
      });
    }
  }
  public getRole$(): Observable<string> {
    return this.role$ ;
  }
  public getRoleValue(): string{
    return this.roleSubject.getValue()
  }
}
