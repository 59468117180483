import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { RolesAccount } from '../../auth/interfaces/auth.constant';
import { GlobalState } from '../../../core/global-state/app.reducer';
import { Store } from '@ngrx/store';
import { AUTH_PARENT_FULL_ROUTES } from '@pages/auth/auth-parent/models/constants/auth-parent.routes.constants';
import { TEEN_ROUTES } from '@pages/teens/constants/teens.route.constants';

@Injectable({
  providedIn: 'root'
})
export class IsParentRoleGuard implements CanActivate,CanActivateChild {
  constructor(private router: Router, private store: Store<GlobalState>) {}

  canActivate(): Observable<boolean| UrlTree> {
    return this.isRoleParent()
  }
  canActivateChild(): Observable<boolean | UrlTree> {
    return this.isRoleParent();
  }

  private isRoleParent(): Observable<boolean | UrlTree> {
    return this.store.select('authentication').pipe(
      first(),
      map(auth => auth.user?.role ?? ''),
      map(role => {
        if (role === RolesAccount.PARENT) {
          return true;
        }
  
        if (role === RolesAccount.TEEN) {
          return this.router.parseUrl(TEEN_ROUTES.DOWNLOAD_APP);
        }
  
        return this.router.parseUrl(AUTH_PARENT_FULL_ROUTES.SIGN_IN_PARENT);
      }),
    );
  }
}
