import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalState } from '@core/global-state/app.reducer';
import { Store } from '@ngrx/store';
import { AUTH_PARENT_FULL_ROUTES } from '@pages/auth/auth-parent/models/constants/auth-parent.routes.constants';
import { RolesAccount } from '@pages/auth/interfaces/auth.constant';
import { TEEN_ROUTES } from '@pages/teens/constants/teens.route.constants';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-redirector',
  template: ''
})
export class RedirectorComponent implements OnInit {
  //#region [---- DEPENDENCIES ----]
  private readonly router: Router = inject(Router);
  private readonly store: Store<GlobalState> = inject(Store)
  //#endregion

//#region [---- LIFE HOOK ----]
  ngOnInit(): void {
    this.store.select('authentication').pipe(first()).subscribe(auth => {
      const role = auth.user?.role;
      if (role === RolesAccount.TEEN) {
        this.router.navigate([TEEN_ROUTES.DOWNLOAD_APP]);
      } else {
        this.router.navigate([AUTH_PARENT_FULL_ROUTES.SIGN_IN_PARENT]);
      }
    });
  }
 //#endregion
}
