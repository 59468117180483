import { CBankingErrorMessages } from "@core/errors/models/constants/product-messages/banking.messages";
import { CUsersErrorMessages, CUsersErrorTitles } from "@core/errors/models/constants/product-messages/users.messages";

export const CErrorMessages = {
    ...CUsersErrorMessages,
    ...CBankingErrorMessages
} as const;

export const CErrorTitles = {
    ...CUsersErrorTitles
}