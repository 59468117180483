export const CTypesButtonOption = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  QUATERNARY: "quaternary",
  QUINARY: "quinary",
  SENARY: "senary",
} as const;

export const CVariantTypesButtonOption = {
  QUATERNARY_BLACK: "quaternary--black",
  QUATERNARY_START: "quaternary--start",
  QUATERNARY_END: "quaternary--end",
} as const;

export const CPositionButtonOption = {
  LEFT: "left",
  RIGHT: "right",
} as const;

export const CButtonTimer = {
  TIME: 3000,
} as const;
