import { CErrorAuthContext } from "@core/errors/models/constants/contexts/auth.context";
import { CErrorChoresContext } from "@core/errors/models/constants/contexts/chore.context";
import { CErrorOnboardingContext } from "@core/errors/models/constants/contexts/onboarding.context";
import { CErrorSendMoneyContext } from "@core/errors/models/constants/contexts/send-money.context";
import { CErrorPasswordContext } from "./contexts/password.context";
import { CErrorReferralsContext } from "@core/errors/models/constants/contexts/referrals.context";
import { CErrorPaymentLinkContext } from "@core/errors/models/constants/contexts/payment-link.context";
import { CErrorSavingsContext } from "@core/errors/models/constants/contexts/savings.context";
import { CErrorPullingCreateTeenContext } from "@pages/parents/pages/onboarding/pages/add-teen/pages/teen-detail/components/pulling-create-teen-account/models/pulling-create-teen-account.constants";

export const CErrorContext = {
    DEFAULT: 'DEFAULT',
    ADD_TEEN: 'ADD_TEEN',
    ADD_TEEN_WITH_INVITATION: 'ADD_TEEN_WITH_INVITATION',
    KYC:'KYC',
    PROFILE:'PROFILE',
    ...CErrorAuthContext,
    ...CErrorChoresContext,
    ...CErrorSendMoneyContext,
    ...CErrorOnboardingContext,
    ...CErrorPasswordContext,
    ...CErrorReferralsContext,
    ...CErrorPaymentLinkContext,
    ...CErrorSavingsContext,
    ...CErrorPullingCreateTeenContext,
    VERIFY_METATADA_NICKNAME: 'VERIFY_METATADA_NICKNAME'
} as const;

