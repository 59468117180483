
export const PARENT_ROUTES = {
    AUTH: '/auth/parents/password',
    PARENTS: '/parents/password',
    ONBOARDING: '/parents/onboarding',
    PARENTS_HOME: '/parents/home'
} as const;

export const PASSWORD_FULL_ROUTES = {
    SET_UP: `${PARENT_ROUTES.AUTH}/set-up`,
    SET_UP_DONE: `${PARENT_ROUTES.PARENTS}/set-up-done`,
    SET_UP_DONE_ONBOARDING: `${PARENT_ROUTES.PARENTS}/set-up-done?goToOnboarding=true`
} as const;

export const CQueryParams = {
    GO_TO_ONBOARDING: 'goToOnboarding'
} as const;