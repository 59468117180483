import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { StaticServeModule } from "@core/static-serve/static-serve.module";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "@shared/pipes/pipes.module";

@Component({
  selector: "mdk-change-mo-card-design",
  templateUrl: "./change-mo-card-design.component.html",
  styleUrls: ["./styles/change-mo-card-design.component.mobile.scss"],
  standalone:true,
  imports: [
    CommonModule, TranslateModule,StaticServeModule,NgOptimizedImage,PipesModule
  ],
})
export class ChangeMoCardDesignComponent implements OnChanges {
  @Input() cardTheme: string;
  @Input() title?: string;
  @Input() cardName: string;
  @Output() cardThemeChange: EventEmitter<void> = new EventEmitter<void>();
  @Input() allowChangeCardTheme?: boolean = true;
  imagePath: string | null = null;
  
  public changedTheme(): void {
    this.cardThemeChange.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardTheme'] && changes['cardTheme'].currentValue) {
      this.resetImagePath();
    }
  }

  private resetImagePath(): void {
    this.imagePath = null;
    setTimeout(() => {
      this.imagePath = this.cardTheme;
    }, 0);
  }
}
