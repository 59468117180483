export const CUsersErrorCodes = {
    TOKEN_EXPIRED: 'tokenExpired',
    PHONE_EXISTS: 'UsersPhoneAlreadyExist',
    EMAIL_EXISTS: 'UsersEmailAlreadyExist',
    INVALID_CREDENTIALS: 'UsersInvalidCredentials',
    USERS_FAMILY_EMAIL_PARENT: 'UsersFamilyProvidedEmailBelongsToParent',
    USERS_FAMILY_TEEN_ALREADY_IN_FAMILY: 'UsersFamilyTeenIsAlreadyInAFamily',
    USERS_FAMILY_EMAIL_PHONE_DONT_MATCH: 'UsersFamilyProvidedEmailPhoneDontMatch',
    INVITATION_CODE_NOT_FOUND: 'UsersInvitationNotFound',
    REFERRAL_CODE_NOT_FOUND: 'UsersReferralCodeNotFound',
    REFERRAL_CODE_DISABLED: 'UsersReferralCodeDisabled',
    REFERRAL_CODE_EXPIRED: 'UsersReferralCodeExpired',
    KYC_IDV_DECLINED: 'BankingKYCDeclined',
    USERS_ITEM_NOT_FOUND: 'UsersItemNotFound',
    USERS_ADDING_MEMBER_WITH_WRONG_ROLE: 'UsersAddingMemberWithWrongRole',
    USERS_ADDING_TEEN_INTO_FAMILY: 'UsersAddingTeenIntoFamily',
    USERS_ADDING_MEMBER: 'UsersAddingMemberExistingInOtherFamily',
} as const;